import * as React from 'react';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { AccessLevel, withAuth, useClaims, RequiredAttributes } from 'evl-ui-lib';

import _isEmpty from 'lodash/isEmpty';

const IndexPage: React.FunctionComponent = () => {
  const authResult = useClaims();
  const router = useRouter();

  useEffect(() => {
    if (!_isEmpty(authResult.claims)) {
      router.push('/vault');
    }
  }, [authResult]);

  return null;
};

export default withAuth(IndexPage, {
  accessLevel: [AccessLevel.customer],
  redirect: '/',
  requiredAttributes: [RequiredAttributes.email, RequiredAttributes.birthdate, RequiredAttributes.location],
});
